@import "breakpoints";

@mixin container() {
    max-width: 1182px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

@mixin flexList() {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

@mixin list {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin divider {
    border-bottom: 0.5px solid rgba(181, 188, 203, 0.56);
    width: 100%;
    box-sizing: border-box;
    height: 1px;
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }

    :-moz-placeholder {
        @content;
    }

    ::-moz-placeholder {
        @content;
    }

    :-ms-input-placeholder {
        @content;
    }
}

@mixin MQ($canvas) {
    @if $canvas == S {
        @media only screen and (max-width: $breakpoint-mobile-md-lg) {
            @content;
        }
    } @else if $canvas == M {
        @media only screen and (max-width: $breakpoint-tablet-md) {
            @content;
        }
    } @else if $canvas == L {
        @media only screen and (max-width: $breakpoint-desktop-sm) {
            @content;
        }
    } @else if $canvas == SS {
        @media only screen and (min-width: $breakpoint-mobile-md-lg) {
            @content;
        }
    } @else if $canvas == MM {
        @media only screen and (min-width: $breakpoint-tablet-md) {
            @content;
        }
    } @else if $canvas == LL {
        @media only screen and (min-width: $breakpoint-desktop-sm) {
            @content;
        }
    }
}

@mixin Button {
    cursor: pointer;
    color: $color-primary-black;
    background: $color-bg-grey-light;
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .03em;
    font-style: normal;
    font-weight: $font-weight-semibold;
    line-height: normal;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: $border-radius-6;
    border: none;
}

@mixin PrimaryButton {
    @include Button;
    color: $color-text-white;
    background: $color-bg-blue;
}

@mixin Error {
    color: $color-text-red;
    font-size: 12px;
    margin-top: 5px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}