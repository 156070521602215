@import "color-palette";
@import "vars";

.checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    &__input {
        display: none;
    }

    &__label {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 13px;
        line-height: 16px;
        color: $color-primary-black;
        font-weight: $font-weight-medium;
    }

    &__box {
        min-width: 20px;
        width: 20px;
        height: 20px;
        border: 2px solid $color-border-grey;
        border-radius: $border-radius-4;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: $color-bg-white;
        margin-right: 8px;
        transition: background-color 0.2s, border-color 0.2s;

        &:hover {
            border-color: $color-border-accent;
        }

        .checkbox__input:checked + .checkbox__label & {
            border-color: $color-border-blue;
        }
    }

    &__checkmark {
        width: 10px;
        height: 5px;
        border-left: 2px solid $color-bg-blue;
        border-bottom: 2px solid $color-bg-blue;
        transform: rotate(-45deg);
    }
}
