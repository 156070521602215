$mainPageLocationFilterZIndex: 99;
$mainPageLocationConfirmZIndex: 90;
$scrollButton: 100;
$galleryButtonsZIndex: 40;
$mobileMenuZIndex: 999;
$cardOnMapZIndex: 39;
$overlayCropper: 30;
$modalCropper: 70;
$deleteCompareCard: 49;
$catalogFilterZIndex: 50;
$layoutUserMenuZIndex: 51;
$catalogMobileCardZIndex: 49;
$placeToCheckFormComplete: 9999;
$favoriteIconZIndex: 49;
$compareIconZIndex: 49;
$stickyCompareCards: 95;
$disabledText: 99;
$consentCookie: 1000;
$hoverGalleryMore: 20;
$hoverGalleryOverlay: 10;
$mobileFullScreenWindow: 9999;
$cursorAnalytics: 300;
$callbackButton: 400;
$lightbox: 500;
$lightboxActions: 510;
$catalogCardHovered: 998;

$loaderContMap: 999;

$horizontalCardButtonsMenu: 9999;
