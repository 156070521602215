@import "color-palette";
@import "vars";
@import "layout-vars";
@import "breakpoints";

.category-filter {
    margin: 40px 0;

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        margin-bottom: 30px;
    }

    &__title {
        font-size: 24px;
        line-height: 29px;
        font-weight: $font-weight-semibold;
        color: $color-primary-black;
        margin-bottom: 30px;
    }

    &__switcher {
        display: flex;
        flex-direction: row;
    }

    &__button {
        width: 80px;
        height: 36px;
        font-size: 13px;
        line-height: 16px;
        font-weight: $font-weight-semibold;
        color: $color-text-aqua;
        text-align: center;
        background-color: transparent;
        border: 1px solid $color-border-blue;
        border-radius: $border-radius-6;
        transition: background-color 0.2s ease, color 0.2s ease;
        cursor: pointer;

        &:first-child {
            border-radius: $border-radius-6 0 0 $border-radius-6;
        }

        &:last-child {
            border-radius: 0 $border-radius-6 $border-radius-6 0;
        }

        &--active {
            background-color: $color-bg-blue;
            color: $color-text-white;
        }
    }

    &__arrows {
        display: flex;
        flex-direction: row;
        gap: 12px;
    }

    &__arrow {
        width: 36px;
        height: 36px;
        border-radius: $border-radius-50-percent;
        background-color: $color-bg-grey-light;
        fill: $color-primary-black;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &--color {
            fill: $color-switcher;
        }
    }
}