@import "zIndexes";
@import "color-palette";
@import "vars";

.MobileSelectInput {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $color-bg-white;
    z-index: $mobileFullScreenWindow;

    h3 {
        padding: 0;
        margin: 15px 0 10px;
        font-weight: $font-weight-semibold;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        color: $color-primary-black;
    }

    li.Selected {
        background: $color-bg-ghost-white;
        font-weight: $font-weight-bold;
    }
}

.CloseBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.OptionsList {
    margin-left: 0;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;

    ul {
        margin: 0;
        padding: 0;
        margin-bottom: 2em;
    }

    li.GroupName {
        > label {
            color: $color-text-grey-dark;
            font-size: 16px;
            padding-left: 21px;
            text-transform: uppercase;
        }
    }

    li.Option {
        padding: 15px 16px 15px 35px;
        display: flex;
        flex-direction: column;
        padding-left: 42px;
        min-height: 40px;
        font-weight: $font-weight-medium;
        font-size: 16px;
        line-height: 20px;
        color: $color-text-blue;
        border-bottom: 0.5px solid $color-border-light;
        cursor: pointer;

        &:hover {
            background: $color-bg-ghost-white;
        }
    }

    li.Active {
        background: $color-bg-ghost-white;
    }

    li.Selected {
        background: $color-bg-ghost-white;
        font-weight: $font-weight-bold;
    }
}

.ImageSelect {
    max-width: 99%;
    padding: 5px;

    li.Option {
        height: 190px;
        padding: 0;
        border-radius: 10px;
    }

    .OptionsList > li > ul {
        width: calc(100% - 10px);
        padding-left: 0;
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 10px;
    }

    .ImageSelector {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background: $color-bg-blue-light;
        border-radius: 10px;
        overflow: hidden;
        height: 100%;
        width: 100%;
    }

    .ImageSelectorLabel {
        margin-top: 5px;
        font-weight: 600;
        text-align: center;
        width: 100%;
        margin-left: 5px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    $imageCont: 73%;

    .ImageCont {
        border-radius: 10px;
        overflow: hidden;
        height: $imageCont;
        min-height: $imageCont;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.Content {
    overflow: scroll;
    height: calc(100vh - 90px);
}

.Search {
    position: relative;
    width: 100%;
    padding: 0 16px;
    margin: 0 0 15px;

    input {
        font-weight: $font-weight-semibold;
        font-size: 22px;
        line-height: 29px;
        padding-left: 25px;
        width: 100%;
        height: 40px;
        border-radius: $border-radius-10;
        border: 1px solid $color-divider-border;
    }

    svg {
        position: absolute;
        right: 22px;
        top: 5px;
    }
}

.NoResults {
    display: none;

    &:nth-child(1) {
        display: block;
        width: 100%;
        text-align: center;
    }
}