@import "color-palette";

.IconLabel {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        max-width: 100%;
        height: auto;
    }

    &__name {
        margin-left: 8px;
        display: flex;
        flex-direction: row;
        min-width: max-content;
    }

    &__generation {
        padding: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        cursor: pointer;
        background: $color-bg-disabled;
        margin-bottom: 3px;


        img {
            padding: .5em;
            margin: 6px;
            width: 100%;
            height: auto;
            border-radius: 20px;
            overflow: hidden;
        }

        .IconLabel__name {
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 4px;
        }
    }
}