@import "color-palette";
@import "vars";
@import "breakpoints";

.LocationButton {
    min-width: 110px;
    height: 30px;
    background-color: $color-bg-blue-dark-light;
    border-radius: $border-radius-8;
    padding: 6px 11px;
    display: flex;
    align-items: center;
    cursor: pointer;

    >svg {
        margin-right: 10px;
        @media (max-width: $breakpoint-tablet-xs) {
            margin-right: 7px;
        }
    }

    label {
        font-weight: $font-weight-semibold;
        font-size: 13px;
        line-height: 16px;
        color: $color-primary-black;
        cursor: pointer;
        @media (max-width: $breakpoint-tablet-xs) {
            font-size: 11px;
        }
    }
}