/* Переменные для border-radius */
$border-radius-3: 3px;
$border-radius-4: 4px;
$border-radius-5: 5px;
$border-radius-6: 6px;
$border-radius-8: 8px;
$border-radius-7: 7px;
$border-radius-10: 10px;
$border-radius-12: 12px;
$border-radius-16: 16px;
$border-radius-19: 19px;
$border-radius-20: 20px;
$border-radius-50: 50px;
$border-radius-100: 100px;
$border-radius-50-percent: 50%;

/* Переменные для font-weight */
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

/* Переменные для теней */
$shadow-deep-dark: 0 0 21px rgba(33, 33, 33, 0.25);
$shadow-mid-dark: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
$shadow-large-faint: 0 10px 30px rgba(0, 0, 0, 0.08);
$shadow-small-faint: 0 6px 30px rgba(0, 0, 0, 0.07);
$shadow-tiny-faint: 0 5px 17px rgba(0, 0, 0, 0.07);
$shadow-mid-faint: 0 8px 30px rgba(0, 0, 0, 0.08);
$shadow-scroll-button: 0 0 8px 2px rgba(0, 0, 0, 0.25);
$shadow-sticky-slider: 0 4px 20px 0 rgba(0, 0, 0, 0.01);
$shadow-slider-arrows: 0 0 8px rgba(0, 0, 0, 0.15);