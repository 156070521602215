@import "mixins";
@import "color-palette";
@import "vars";
@import "breakpoints";

.Vertical {
    display: inline-grid;
    width: 100%;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    @media screen and (max-width: $breakpoint-catalog-three-columns) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: $breakpoint-mobile-lg) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $breakpoint-catalog-one-column) {
        grid-template-columns: repeat(1, 1fr);
    }

    > li {
        width: 100%;
        min-width: 0;
    }
}

.Horizontal {
    display: flex;
    flex-direction: column;
}

.CategoryChart {
    margin-bottom: 30px;
}

.CatalogList {
    overflow-anchor: none;
    margin-top: 30px;
    margin-bottom: 30px;
    align-items: center;

    h1,
    .HeaderTitle {

        font-weight: $font-weight-semibold;
        font-size: 24px;
        line-height: 29px;
        color: $color-primary-black;
        padding: 0;
        margin: 0;
        position: relative;
    }
}

.Header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.HeaderMenu {
    display: flex;
    align-items: center;
}

.CardList {
    @include list;
    margin-bottom: 40px;
}

.Divider {
    width: 0.5px;
    height: 30px;
    border-left: 0.5px solid $color-divider-border;
    margin: 0 20px;
}

.HorizontalBtn {
    margin-right: 10px;
}

.HorizontalBtn,
.VerticalBtn,
.Divider {
    @media screen and (max-width: $breakpoint-tablet-lg) {
        display: none;
    }
}

.Select {
    margin-right: 16px;
    @media (max-width: $breakpoint-select-display-none) {
        display: none;
    }
}

.Select2 {
    @media (max-width: $breakpoint-mobile-md) {
        display: none;
    }
}

.ForcedToShow {
    &.Header {
        @media (max-width: $breakpoint-catalog-show) {
            flex-direction: column;
            align-items: baseline;

            .Select,
            .Select2 {
                display: block;
                margin-top: 1em;
            }
        }
    }
}

.PrimaryButton {
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    width: 220px;
    height: 56px;
    border: 1px solid $color-border-blue;
    border-radius: $border-radius-10;
    background-color: $color-bg-white;

    font-weight: $font-weight-semibold;
    font-size: 15px;
    line-height: 18px;

    color: $color-primary-blue;
}

.PrimaryButton {
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    width: 220px;
    height: 56px;
    border: 1px solid $color-border-blue;
    border-radius: $border-radius-10;
    background-color: transparent;

    font-weight: $font-weight-semibold;
    font-size: 15px;
    line-height: 18px;
    color: $color-primary-blue;
}

.Error {
    width: 200%;

    @media (max-width: $breakpoint-tablet-lg) {
        width: 100%;
    }
}

.HeaderCont {
    position: relative;
}

.Total {
    font-size: 12px;
    font-weight: normal;
    position: relative;
    top: -9px;
    width: 50px;
    display: inline;

    @media screen and (max-width: $breakpoint-mobile-md-sm) {
        font-size: 12px;
        font-weight: normal;
        position: relative;
        top: -8px;
        right: 0;
        display: inline;
    }
}