@import 'color-palette';
@import "vars";

.LocationConfirm {
    padding: 16px 24px;
    width: 208px;
    background: $color-bg-white;
    border: 0.5px solid $color-border-light;
    box-shadow: $shadow-tiny-faint;
    border-radius: $border-radius-8;

    display: flex;
    flex-direction: column;
    gap: 10px;

    &__question {
        font-size: 14px;
        font-weight: $font-weight-medium;
    }

    &__button {
        font-size: 14px;
        border: none;
        border-radius: $border-radius-6;
        padding: 6px 12px;
        font-weight: $font-weight-medium;
        cursor: pointer;

        &-confirm {
            background-color: $color-bg-blue;
            color: $color-text-white;
        }

        &-change {
            border: 1px solid $color-bg-blue;
            background-color: $color-bg-white;
            color: $color-text-aqua;
        }
    }
}