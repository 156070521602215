/* Основная палитра */
$color-primary-black: #1D1D1B;
$color-primary-blue: #3B85F7;

/* Акцентные цвета */
$color-text-accent: #747F94;
$color-input-accent: #7B869B;
$color-text-white: #FFFFFF;
$color-text-grey-light: #949CA8;
$color-text-grey: #9E9E9E;
$color-text-grey-dark: #808080;
$color-text-blue: #26365C;
$color-text-aqua: #3B85F7;
$color-text-black: #000000;
$color-text-green: #43a151;
$color-text-green-light: #51C463;
$color-text-yellow: #E8A14D;
$color-text-black-green: #343B47;
$color-text-red-strong: #FF0000;
$color-text-red-bright: #CD0000;
$color-text-red: #C53131;
$color-text-brown: #A52A2AFF;
$color-text-brown-black: #272727;
$color-text-pink: #F86A6A;
$color-text-storm-blue: #7B869B;
$color-text-lavender: #818BA1;

/* Цвета фона */
$color-bg-white: #FFFFFF;
$color-bg-accent: #7B869B;
$color-bg-yellow: #E8A14D;
$color-bg-ghost-white: #F6F8FB;
$color-bg-smoky-white: #F8F8F8;
$color-bg-grey: #EBEBEB;
$color-bg-black: #1D1D1B;
$color-bg-black-light: rgba(29, 29, 27, 0.5);
$color-bg-black-little-light: rgba(0, 0, 0, 0.7);
$color-bg-black-origin: #000000;
$color-bg-grey-light: #F4F7FA;
$color-bg-grey-dark: #747F94;
$color-bg-green: #51c463;
$color-bg-green-dark: rgba(81, 196, 99, 0.1);
$color-bg-blue: #3B85F7;
$color-bg-blue-dark: #343B47;
$color-bg-blue-dark-light: #3B85F719;
$color-bg-disabled: #F5F5F5;
$color-bg-hidden: #061c2e80;
$color-bg-indicator: rgba(52, 59, 71, 0.23);
$color-bg-blue-light: #F5F8FD;
$color-bg-pink: #F86A6A;
$color-bg-pink-light: #F7D3D2;
$color-bg-red: #DA3832;
$color-bg-logo: #EFF3F8;
$color-bg-footer: #2A2F4D;
$color-bg-chart: rgba(59, 133, 247, 0.05);
$color-bg-card: rgba(116, 127, 148, 0.03);
$color-bg-card-green: rgba(81, 196, 99, 0.03);
$color-bg-bar: rgba(222, 233, 227, 0.62);
$color-bg-refused: rgba(248, 106, 106, 0.03);
$color-bg-mist: rgba(232, 114, 77, 0.03);
$color-bg-graphite: #333333;
$color-bg-smoke-grey: rgba(29, 29, 27, 0.69);
$color-bg-pagination: rgba(59,133,247,.098);
$color-bg-store: #212328;

/* Дополнительные цвета при действиях, для границ и разделителей */
$color-border-white: #FFFFFF;
$color-border-black: #1D1D1B;
$color-border-accent: #7B869B;
$color-border-grey: #E5E5E5;
$color-border-silver: #D6D9E0;
$color-border-light: #E2E5EB;
$color-border-blue: #3B85F7;
$color-border-pink: #F7D3D2;
$color-divider-border: #B5BCCB;
$color-divider-dark: #989FAC;
$color-active-white: #FFFFFF;
$color-active-green: #06ce86;
$color-switcher: #343B47;
$color-blackout: rgba(6, 28, 46, 0.7);
$color-border-catalog: rgba(181, 188, 203, 0.56);
$color-border-chart: rgba(181, 188, 203, 0.38);
$color-border-compare: rgba(181, 188, 203, 0.38);
$color-border-grey-light: #D6D9E2;
