@import "color-palette";
@import "vars";

.select-input {
    &__disabled {
        background-color: $color-bg-disabled;
        border-radius: $border-radius-10;
        color: $color-text-grey;
        cursor: not-allowed;
    }
}