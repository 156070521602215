@import "color-palette";
@import "breakpoints";
@import "vars";

.filter-card {
    width: 100%;
    max-width: 273px;
    background-color: $color-bg-grey-light;
    border-radius: $border-radius-12;
    position: relative;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%) scale(0);
        background: radial-gradient(
            circle,
            rgba($color-bg-blue, 0.4) 0%,
            rgba($color-bg-blue, 0.2) 50%,
            transparent 100%
        );
        filter: blur(25px);
        transition: transform 0.2s ease, opacity 0.2s ease;
    }

    &:hover {
        &::before {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0 8px;
        position: relative;

        @media screen and (max-width: $breakpoint-mobile-lg) {
            padding: 12px 0 6px;
        }
    }

    &__image {
        width: 240px;
        max-width: 100%;
        height: 160px;
        object-fit: contain;

        @media screen and (max-width: $breakpoint-desktop-xs) {
            width: 190px;
            height: 126px;
        }

        @media screen and (max-width: $breakpoint-mobile-lg) {
            width: 160px;
            height: 106px;
        }
    }

    &__name {
        display: inline-block;
        background-color: $color-bg-blue;
        font-size: 16px;
        font-weight: $font-weight-semibold;
        color: $color-text-white;
        border-radius: 0 $border-radius-12;
        padding: 4px 8px;
        text-align: center;

        @media screen and (max-width: $breakpoint-mobile-lg) {
            font-size: 12px;
        }
    }
}
