@import "breakpoints";

.ImageStaticAds {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 480px;

    @media (max-width: $breakpoint-catalog-one-column) {
        min-height: 690px;
    }
}
