@import "color-palette";
@import "vars";
@import "breakpoints";

.analytics-banner {
    background: $color-bg-blue-light;
    border-radius: $border-radius-12;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 20px;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }

    &__top,
 &__bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__top {
        gap: 20px;
        height: 90px;
        font-size: 18px;
        font-weight: $font-weight-semibold;
        line-height: 22px;
    }

    &__bottom {
        gap: 16px;

        @media screen and (max-width: $breakpoint-tablet-xs) {
            flex-direction: column;
            gap: 0;
        }
    }

    &__icon {
        align-self: flex-end;
    }

    &__action {
        border-radius: $border-radius-10;
        background: $color-bg-blue;
        width: 300px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 19.5px;
        color: $color-text-white;
        font-weight: $font-weight-semibold;

        &:hover {
            transition: box-shadow 0.3s;
            box-shadow: $shadow-deep-dark;
        }
    }

    @media screen and (max-width: $breakpoint-desktop-sm) {
        flex-direction: column;
        height: auto;
        padding: 20px;
        gap: 0;
    }
}