@import "color-palette";
@import "vars";

.Row {
    display: flex;
    gap: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
}

.Col {
    flex-grow: 1;
    width: 100%;

    label {
        display: block;
        font-weight: $font-weight-medium;
        font-size: 13px;
        line-height: 16px;
        color: $color-text-blue;
        margin-bottom: 5px;
    }
}